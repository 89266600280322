.errorPage .cardCopy{
    background-color: #F9DEDC;
}
.errorPage .title-card{
    font-size:16px;
    color: #B3261E;
}
.errorPage .legend-card{
    font-size:14px;
    color:#B3261E;
}

.errorPage .code-card{
    color: #B3261E;
}
.errorPage .content{
    font-size:14px;
    color:#79767D;
}
.errorPage {
    background-color:#F9DEDC;
    justify-content:center;
    align-items: center;
}
.errorPage button{
    background-color: #2BB741;
}
.errorPage .div-content{
    display: flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    color:#B3261E;
    font-weight:500;
    font-size: 14px;
    text-align:center;
    padding: 0 10px;
}
.errorPage .div-content img{
    width: 77px;
    object-fit: cover;
}

.errorPage .button-whats{

}
.errorPage .button-whats img{
    margin: -4px 9px 0 0;
}
@media only screen and (min-width: 700px) {
    .errorPage {
        justify-content:normal;
    }

}
