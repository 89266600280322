.App {
    height:100vh;
    overflow:hidden;
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}
.App .base button{
    border:none;
    border-radius: 12px;
    color:white;
    height:60px;
    font-weight:bold;
}
.App .base .card-absolute{
    padding: 16px;
    border-radius: 12px 12px 0 0;
    background-color:white;
    height: fit-content;
}
.App .base .card-logo{
    border-radius: 0;
    justify-content:center;
}
.App .base .logo{
    width: 110px;
    object-fit: cover;
}
.App .base{
    min-height:100vh;
    display: flex;
    flex-direction: column;
    overflow:hidden;
}
.App .base hr{
    margin: 8px 0;
}
.App .loadPage{
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
    text-align:center;
    z-index: 25;
    transition: top 1s;
}
.App .loadPage.hide {
    top: -100%;
}
.App .loadPage .content{
    color: var(--color-column-red);
    font-size: 16pt;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.App .loadPage p{
    color:#ef6f22;
}
.App .loadPage .divLoad{
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}