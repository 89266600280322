.startPage {
    background-color:#f5eefa;
    justify-content:center;
    align-items: center;
}
.startPage .cardCopy{
    background-color: #D8EBFC;
}
.startPage .cardCopy:hover{
    background-color: #E8F4FF;
    cursor:pointer;
}
.startPage button{
    background-color: #2F7DC1;
}
.startPage button:hover{
    background-color: #69A8DF;
}
.startPage .title-card{
    font-size:16px;
    color: #2F7DC1;
}
.startPage .legend-card{
    font-size:14px;
    color: #79767D;
}

.startPage .code-card{
    color: #2F7DC1;
}
.startPage .icon{
    width: 12px;
    height:12px;
    margin: 4px 6px 2px 2px;
}
.startPage .content{
    font-size:14px;
    color:#79767D;
}
.startPage .div-qrcode{
    display:flex;
    justify-content:center;
}

.startPage .div-qrcode canvas{
    margin-top:17px;
}
.startPage .line-content{
    margin-bottom: 8px
}
@media only screen and (min-width: 700px) {
    .startPage {
        background-color:white;
        justify-content:normal;
    }

}