.cardCopy{
    border:none;
    border-radius: 12px;
    padding: 8px 16px;
    display:flex;
    justify-content:space-between;
    margin: 10px 0;
}
.cardCopy .div-card{
    display:flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.cardCopy .div-card .title-card{
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
}
.cardCopy .div-card .code-card{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 500;
}
.cardCopy img{
    width:19px;
    object-fit: cover;
}

.cardCopy .img-card{
    height:100%;
    padding: 0px 6px 0px 20px ;
    display: flex;
    flex-direction: column;
    justify-content:center;
}
