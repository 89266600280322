.divLoad .wrapper{
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 44px;
}
.divLoad .loadText{
    color: white;
    font-weight:bold;
    font-size:9pt;
    margin-top:30px;
}
.divLoad .loader{
  transform: rotate(90deg);
  display: flex;
  position: relative;
  width: 80px;
  height: 44px;
}

.divLoad .wave{
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  perspective: 100px;
}

.divLoad .dot{
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.divLoad .dot::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #aaaaaa;
  border-radius: 50%;
}

.divLoad .top-wave .dot::before {
  background-color: #ff9436;
}
.divLoad .bottom-wave .dot::before {
  background-color: #fcd135;
}
.divLoad .top-wave > div{
  animation: move 3s ease-in-out infinite reverse;

 }

.divLoad .top-wave > div::before{
  animation: grow 3s linear infinite reverse;
}

.divLoad .bottom-wave > div{
  animation: move 3s ease-in-out infinite;
 }

.divLoad .bottom-wave > div::before{
  animation: grow 3s linear infinite;
}

@keyframes move{
  0%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(44px);
  }
  50%{
    transform: translateY(0px);
  }
  75%{
    transform: translateY(44px);
  }
  100%{
    transform: translateY(0px);
  }

}

@keyframes grow{
  0%, 50%, 75%, 100% {
    transform: scaleX(0.7) scaleY(0.7) scaleZ(0.7);
  }
  10%, 60% {
    transform: scaleX(1) scaleY(1)  scaleZ(1);
  }
  35%, 85% {
    transform: scaleX(0.4) scaleY(0.4) scaleZ(0.4);
    z-index:-1px
  }
}