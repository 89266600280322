.finishPage .cardCopy{
    background-color: #d9f2dc;
}
.finishPage {
    background-color:#d9f2dc;
    justify-content:center;
    align-items: center;
}
.finishPage button{
    background-color: #488732;
}
.finishPage .title-card{
    font-size:16px;
    color: #488732;
}
.finishPage .code-card{
    color: #488732;
}
.finishPage .content{
    font-size:14px;
    color:#488732;
}
.finishPage .card-title{
    text-transform: uppercase;
}
.finishPage ol{
    font-size:18px;
    padding-left:20px !important;
    font-weight:500;
}
.finishPage hr{
    opacity: 0.6;
    border: 1px solid #488732;
}
@media only screen and (min-width: 700px) {
    .finishPage {
        justify-content:normal;
    }

}