.content{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    position:relative;
    padding: 16px 0 16px 0;
}
.content .container{
    flex: 1 1 auto;
    overflow-y: auto;
    height: 100px;
}
@media only screen and (min-width: 700px) {
    .content{
        flex-grow: 0;
    }
    .content .container{
        height: auto;
    }

}